@import "colors";
.home{
    width: 100%;
    height: 100vh;
    background-color: $second;
    

    main{
        position: absolute;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        padding: 2rem;
        justify-content: center;
        @media only screen and (max-width: 588px) {
            justify-content: flex-end;
        }

        h1{
            font:400 2rem $text1;
            color: $first;
            z-index: 0;
            @media only screen and (max-width: 588px) {
                font-size: 1.4rem;
                color: rgb(173, 244, 66);
            }
        }
        p{
            font:900 1rem $text1;
            color: $first;
            z-index: 0;
        }

    }

    @media only screen and (min-width: 588px) {
// sudo selector background change kr rhy hai isley

&::before{ 
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: no-repeat center/auto url('../assets/pro7.png');
    animation: imgUp 0.5s linear infinite alternate;
    // filter: blur(1px);
    border-radius: 0 0 200px 200px;
}

    }

}

.home2{
    width: 100%;
    height: 100vh;
    background-color: $second;
    padding: 500px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -200px;

    img{
        width: 22%;
        animation: imgUp 0.7s linear infinite alternate;
        @media only screen and (max-width: 588px) {
            width: 50%;
        }
    }

    div{
        width: 50%;
        @media only screen and (min-width: 588px) {
            border-radius: 24%;
        }
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 5rem;
        height: 100vh;


        p{
            color: $first;
            letter-spacing: 2px;
            word-spacing: 5px;
            font: 100 1.2rem $text2;
        }
    }
}

@keyframes imgUp {
to {
    transform: translate(-10px);
}
}


.home3{
    width: 100%;
    height: 120vh;
    background-color: $second;
    margin-top: -200px;
    display: flex;

    div{
        width: 100%;
        @media only screen and (min-width: 588px) {
            
            width: 70%;
            
         }

        
        height: 100vh;
        background-color: $first;
        @media only screen and (max-width: 588px) {
            
            border-radius: 7%;
        }
        @media only screen and (min-width: 588px) {
            border-radius: 0 200px 200px 0; 
            padding: 2rem;
            }
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        

        h1{
            text-transform: uppercase;
            border-bottom: 2px solid $dark;
            padding: 0.5rem;
            width: 15rem;
            color: $dark;
        }

        p{
            padding: 3rem;
            @media only screen and (min-width: 588px) {
            
                padding: 5rem;
             }
            letter-spacing: 1px;
            word-spacing: 5px;
            line-height: 170%;
            font: italic 100 1.2rem $text2;
            
        }
    }

}

.home4{
    width: 100%;
    height: 100vh;
    background-color: $second;
    display: flex;
    justify-content: flex-end;

    .homediv{
        background-color: $third;
        width: 70%;
        @media only screen and (max-width: 588px) {
            
            width: 100%;
         }
         @media only screen and (min-width: 588px) {
        border-radius: 200px 0 0 200px;
         }
        width: 70%;
        height: 70vh;
        @media only screen and (max-width: 588px) {
            height: 80vh;
            border-radius: 7%;
        }
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1{
            color: $first;
            text-transform: uppercase;
            width: auto;
            border-bottom: 2px solid $first;
            padding: 0.5rem;

        }

        article{
            display: flex;
            margin: 2rem 0;
            flex-wrap: wrap;
            justify-content: center;

            div{
                animation: imgUp 0.7s linear infinite alternate;
                margin: 1rem;
                height: 10rem;
                width: 10rem;
                @media only screen and (max-width: 588px) {
                width: 7rem;
                }
                background-color: $first;
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: $dark;
                cursor: pointer;
                transition: all 0.5sec;
            &:hover{
                filter: invert(1);

            }

            svg{
                font-size: 4rem;

            }
            p{
                font: 900 1.2rem $text2;
                

            }

        }
    }

    }
}


// height: 100px;
//     width: 100px;
//     background-image: url(http://localhost:3000/static/media/pro7.ea6401a….png);

@media only screen and (max-width: 588px) {

    .home{
        main{
            padding: 0px;

            &::before{ 
                content: "";
                width: 100vw;
                height: 100%;
                position: absolute;
                background: no-repeat center/auto url('https://edunewsnetwork.files.wordpress.com/2021/06/shutterstock-381607366-1024x612-1.jpg');
                background-size: cover;
                // animation: imgUp 0.5s linear infinite alternate;
                // filter: blur(1px);
                // border-radius: 0 0 200px 200px;
                // z-index: -1;
            }
            
        }
    }
}
    
    