@import "colors";

// nav {

//     width: 100%;
//     padding: 1rem;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     position: sticky;
//     background-color: $first;
//     top: 0%;
//     z-index: 10;

// }
// h1{
//     text-transform: uppercase;
//     font-family: $text2;

// }
// main
// {
//     width: 70%;
//     display: flex;
//     justify-content: flex-end;


//     a{
//         color: $dark;
//         margin: 1rem;
        
//         &:hover {
//             color: $third;
//         }
//     }
// }


@media only screen and (min-width: 988px) {

    
.navbar {
    width: 100%!important;
    padding: 1rem!important;
    display: flex!important;
    justify-content: space-between!important;
    align-items: center!important;
    position: sticky!important;
    background-color: $first!important;
    top: 0%!important;
    z-index: 10!important;

    .navbar-brand{
    text-transform: uppercase!important;
    font-family: $text2!important;
       
    }

    .main{
    width: 70%!important;
    display: flex!important;
    justify-content: flex-end!important;
    }
    a{
        color: $dark!important;
        margin: 1rem!important;
        
        &:hover {
            color: $third!important;
        }
    }

}
    
  }


