@import "colors";


.blog {
    max-width: 600px;
    margin: 0 auto;
  
    &__title {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
      font-family: $text1;
    }
  
    &__description {
      font-size: 1.2rem;
      color: gray;
      margin-bottom: 1rem;
    }
  
    &__image {
      max-width: 100%;
      height: auto;
      margin-bottom: 1rem;
      border: 1px solid #d9d5d5;
    }
  
    &__article {
      font-size: 1.2rem;
      line-height: 1.5;
      white-space: pre-wrap;
     
    }

    
  }
  

  .location{
    font-weight: bold;
  }
 