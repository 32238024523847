.news-card {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
    border: 1px dashed #786e6e;
    background-color: rgb(235, 205, 52);
    @media only screen and (max-width: 588px) {
      flex-direction: column;
      background-color: #fffae1;
      border: .2px solid #786e6e;
       }
  
    .news-card-image {
      width: 120px;
      height: 120px;
      overflow: hidden;
      @media only screen and (max-width: 588px) {
        height: 212px;
      }
  
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media only screen and (max-width: 588px) {
          
          height: 24%;
          width: auto;
          position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
           }

        
      }
    }
  
    .news-card-content {
      flex: 1;
      margin-left: 16px;
  
      .news-card-title {
        font-size: 18px;
        font-weight: bold;
        margin: 0 0 8px;
      }
  
      .news-card-meta {
        display: flex;
        font-size: 14px;
        color: #666;
        margin: 0 0 8px;
  
        p {
          margin: 0 8px 0 0;
  
          &:last-child {
            margin: 0;
          }


         
          
        }
      }
  
      .news-card-description {
        font-size: 16px;
        color: #333;
      }
    }
  }

  .readmore{
    color: #2202ff;
    &:hover {
      color: red;
  }
  }