@import "colors";

#contact{
    h1{
        align-items: center;
        text-align: center;
        font-size: 2rem;
        font-family: $text1;
    }
}

#contact-box{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 34px;
}
#contact-box form{
    width: 40%;

}
#contact-box label{
    font-size: 1.3rem;
    font-family: 'Bree Serif', serif;
}
#contact-box input,#contact-box textarea{
    width: 100%;
    padding: 0.5rem;
    border-radius: 8px;
    font-size: 1.1rem;
}